import React from "react"
import Layout from "../../components/Layout"
import uwuImg from "../../../static/blogs/1.jpg"

const WhereItAllBegins = () => {
  return (
    <Layout>
      <main className={"artist-image w-100 flex-center-rw"}>
      </main>
      <section className={"artist-bio w-100"}>
        <div className={"artist-img"}>
          <img src={uwuImg} alt="aykunna artist image" />
          <a href="#" className={"follow-yt"} target="_blank">Watch on Youtube</a>
          <a href="#" className={"follow-tw"} target="_blank">Follow on Twitter</a>
        </div>
        <div className={"artist-info"}>
          <h2>Where It All Begins</h2>
          <p>Those goofy smiles and her dreams were beautiful as those first grassy
            steps. Now, here she is. The name is Aykunna. With her parents, she
            was sliding into a place with 24 different time zones holding love and
            power together. <br /><br />
            There were no stories about Adam & Eve, no philosophical push-ups,
            no rhymes about cows & hills, but stories of hope and resilience of
            strong women whose revival led to many broken wolves coming
            together.<br /><br />
            While her mom is humming her favourite song to the magicians who
            brought her home, just like the amber-orange kittens, she was small
            and happy. There were fifty-seven seconds of continuous laughter and
            three minutes of continuous screams and cries. Her mother's first
            words to her were, "Every time you fall, you'll rise back stronger."
            Wasn't it obvious that this was just the beginning of something big?</p>
        </div>
      </section>
    </Layout>
  )
}

export default WhereItAllBegins